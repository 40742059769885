.no-margin, .no-margin button {
	margin: 0 0 0 0;
}

.no-padding, .no-margin button {
	padding: 0 0 0 0;
}

.top-margin {
	margin-top: 10px;
}

.vertical-margin {
	margin-top: 10px;
	margin-bottom: 10px;
}

.vertical-padding {
	padding-left: 10px;
	padding-right: 10px;
}

.checkbox-table .checkbox {
	margin: 4px 0 0 7px;
}

.checkbox {
	margin: 0 0 0 0;
}

.actions {
	margin: 10px 0 20px 0;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.border-bottom {
	border-bottom: solid 1px black;
}

.glyphicon-spin {
	-webkit-animation: spin 1000ms infinite linear;
	animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 3px;
	vertical-align: middle;
}

.width100 {
	width: 100px;
}

.width150 {
	width: 150px;
}

.width40 {
	width: 40px;
}

.table th {
	border:none;
}
